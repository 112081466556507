<template>
	<div>
		<a-row type="flex" align="middle" justify="start" class="header row">
			<a-col flex="1 1 350px">
				<span class="header-title">Produtos relacionados</span>
			</a-col>
			<a-col flex="0 1 100px">
				<a-button
					v-if="showProductDrawer"
					icon="plus"
					:disabled="!active"
					@click="showAddProduct"
				>
					Novo produto
				</a-button>
			</a-col>
		</a-row>
		<a-row
			type="flex"
			align="middle"
			justify="start"
			:gutter="[8, 8]"
			class="filters"
			style="margin: 0 0 8px 0;"
		>
			<a-col :span="16">
				<a-input-group compact>
					<a-input
						v-model="filterString"
						style="width: 70%;"
						placeholder="Filtrar produtos por... "
						@change="handleChangeFilterString"
					>
						<a-icon slot="prefix" type="search" />
					</a-input>
					<a-select
						v-model="filterOption"
						style="width: 30%;"
						@change="handleChangeFilterOption"
					>
						<a-select-option
							v-for="key in Object.keys(selectOptions)"
							:key="selectOptions[key].key"
							v-model="selectOptions[key].key"
						>
							{{ selectOptions[key].text }}
						</a-select-option>
					</a-select>
				</a-input-group>
			</a-col>
			<a-col :span="8">
				<a-select
					style="width: 90%; margin-left: 8px;"
					:value="sortMode"
					@change="handleSortChange"
				>
					<a-select-opt-group>
						<span
							slot="label"
							style="font-size: 11px; color: rgba(0, 0, 0, 0.75);"
						>
							ORDENAR POR
						</span>
						<a-select-option
							v-for="key in Object.keys(selectOptions)"
							:key="selectOptions[key].key"
						>
							{{ selectOptions[key].text }}
						</a-select-option>
					</a-select-opt-group>
				</a-select>
			</a-col>
		</a-row>
		<div class="list-container">
			<a-list
				v-if="loading"
				item-layout="horizontal"
				:data-source="Array.from({ length: 20 }, (_, i) => i + 1)"
			>
				<a-list-item
					slot="renderItem"
					key="item.title"
					slot-scope="item"
				>
					<a-skeleton
						:loading="true"
						:title="false"
						:paragraph="{ rows: 1, width: ['100%'] }"
						active
					>
						{{ item }}
					</a-skeleton>
				</a-list-item>
			</a-list>
			<a-list
				v-else
				item-layout="horizontal"
				:data-source="productsList"
				:locale="ptBR"
				style="width: 100%;"
			>
				<a-list-item
					slot="renderItem"
					slot-scope="item, index"
					style="padding: 8px 0; max-width: 100%;"
				>
					<a-checkbox
						v-if="active"
						slot="actions"
						:checked="item.checked"
						@change="(e) => onCheckedChange(index, item.id, e)"
					/>
					<a-checkbox
						v-else
						slot="actions"
						:checked="item.checked"
						disabled
					/>

					<a-list-item-meta v-if="item">
						<div slot="title" class="name">
							<span v-if="item.external_code">
								{{ `${item.external_code} - ` }}
							</span>
							<span>
								{{ item.name }}
							</span>
						</div>
					</a-list-item-meta>
				</a-list-item>
			</a-list>
		</div>
		<ProductDrawer
			v-if="showProductDrawer"
			ref="productDrawer"
			:title="productDrawerTitle"
			@save="handleSaveProduct"
		/>
	</div>
</template>

<script>
import { ProductDrawer } from '../../productsPage/components'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { toNormalize } from '@/utils/utils'

const selectOptions = Object.freeze({
	code: {
		key: 'external_code',
		text: 'código',
	},
	name: {
		key: 'name',
		text: 'nome',
	},
})

export default {
	name: 'ProductsList',
	components: {
		ProductDrawer,
	},
	props: {
		active: {
			type: Boolean,
			required: true,
			default: false,
		},
		showProductDrawer: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			ptBR: { emptyText: 'Nenhum produto disponível para relacionar' },
			allProducts: [],
			productsList: [],
			loading: true,
			selectOptions,
			filterString: '',
			productDrawerTitle: '',
		}
	},
	computed: {
		...mapGetters({
			gPFilterOption: 'ux/groupPageFilterOption',
			gPsortMode: 'ux/groupPageSortMode',
		}),
		filterOption: {
			set: function (value) {
				this.setGroupPageFilterOption(value)
			},
			get: function () {
				return this.gPFilterOption
			},
		},
		sortMode: {
			set: function (value) {
				this.setGroupPageSortMode(value)
			},
			get: function () {
				return this.gPsortMode
			},
		},
		hasProducts: function () {
			return true
		},
		filteredProductsList: {
			get: function () {
				return [...this.productsList]
			},
			set: function (products) {
				if (this.filterString === '') {
					this.productsList = [...products]
				} else {
					const searchString = toNormalize(this.filterString)
					const regex = new RegExp(searchString, 'i')

					this.productsList = products.filter((product) => {
						const toTest = toNormalize(product[this.filterOption])

						return toTest.match(regex)
					})
				}

				this.handleSortChange(this.sortMode)
			},
		},
	},
	methods: {
		...mapActions('merchants', ['addProduct']),
		...mapMutations('ux', [
			'setGroupPageSortMode',
			'setGroupPageFilterOption',
		]),
		onCheckedChange(index, productId, e) {
			const { checked } = e.target

			this.$emit('checked-change', {
				productId,
				value: checked,
			})

			this.productsList[index].checked = checked
			this.filteredProductsList = this.productsList
		},
		setProductsList(products) {
			this.loading = true
			this.allProducts = products
			this.filteredProductsList = products
			this.loading = false
		},
		clearList() {
			this.productsList = []
			this.allProducts = []
			this.loading = true
		},
		handleSortChange(value) {
			this.sortMode = value
			this.sortProductsBy(value)
		},
		sortProductsBy(property) {
			const func = (a, b) => {
				const va = toNormalize(a[property])
				const vb = toNormalize(b[property])

				return va > vb ? 1 : vb > va ? -1 : 0
			}

			this.productsList.sort(func)
		},
		handleChangeFilterString(e) {
			this.filterString = e.target.value
			this.filteredProductsList = this.allProducts
		},
		showAddProduct() {
			this.productDrawerTitle = 'Adicionar produto'
			this.$refs.productDrawer.setShowDrawer(null, false)
		},
		async handleSaveProduct(product) {
			try {
				this.$message.loading({
					content: 'Adicionando produto...',
					key: 'updatable',
				})

				const res = await this.addProduct(product)

				this.allProducts.push(res)
				this.filteredProductsList = this.allProducts
			} catch (error) {
				//
			} finally {
				this.$message.success({
					content: 'Produto adicionado com sucesso',
					key: 'updatable',
					duration: 4,
				})
			}
		},
		handleChangeFilterOption() {
			this.filteredProductsList = this.allProducts
		},
	},
}
</script>

<style lang="less" scoped>
.header {
	padding: 8px 16px 8px 24px;
	margin: 24px 0 0;
	width: 100%;
	border-top: 1px solid #e8e8e8;
	border-bottom: 1px solid #e8e8e8;

	.header-title {
		color: rgba(0, 0, 0, 0.65);
	}
}

.filters {
	margin: 0;
	padding: 0 8px 0 16px;
	background-color: #f1f1f1;
	height: 48px;
}

.list-container {
	padding-left: 8px;
	padding-right: 8px;
	overflow: auto;
	max-height: 618px;

	.name {
		padding-left: 16px;
		white-space: nowrap;
		overflow-x: hidden;
		max-width: 454px;
		text-overflow: ellipsis;

		font-size: 0.85rem;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.8);
	}
}
</style>
