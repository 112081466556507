<template>
	<a-drawer
		:title="title"
		width="760px"
		:closable="false"
		:visible="showDrawer"
		@close="onClose"
	>
		<div>
			<a-tabs type="card">
				<a-tab-pane key="1" tab="Informações">
					<a-form>
						<a-row type="flex" justity="start" :gutter="36">
							<a-col :span="17">
								<a-form :form="form">
									<a-form-item
										:label="`Código do produto`"
										style="width: 160px; margin: 0;"
									>
										<div class="size-span-wrapper">
											<a-input
												v-model="element.external_code"
												v-mask="'#####'"
												:auto-focus="true"
												placeholder="00000"
											/>
											<span
												class="size-span"
												style="padding-top: 3px;"
											>
												{{
													element.external_code
														.length
												}}/5
											</span>
										</div>
									</a-form-item>
									<a-form-item
										:label="`Nome do produto`"
										style="margin: 0;"
									>
										<div class="size-span-wrapper">
											<a-input
												v-model="element.name"
												:max-length="50"
												placeholder="Informe o nome do produto"
											/>
											<span
												class="size-span"
												style="padding-top: 3px;"
											>
												{{ element.name.length }}/50
											</span>
										</div>
									</a-form-item>
									<a-form-item
										label="Descrição"
										style="margin: 0;"
									>
										<div class="size-span-wrapper">
											<a-textarea
												v-model="element.description"
												:max-length="250"
												placeholder="Informe a descrição do produto"
											/>
											<span class="size-span">
												{{
													element.description.length
												}}/250
											</span>
										</div>
									</a-form-item>
									<a-form-item
										label="Valor padrão do produto"
										style="margin: 0;"
									>
										<a-input
											v-model.lazy="element.price"
											v-money="money"
											addon-before="R$"
											style="width: 160px;"
										/>
									</a-form-item>
									<a-form-item
										label="Grupo operacional"
										style="margin: 0;"
									>
										<a-input-group compact>
											<a-select
												v-model="element.group_id"
												placeholder="Nenhum grupo selecionado"
												:style="
													showGroupDrawer
														? 'width: 90%'
														: 'width: 100%'
												"
												:loading="loading"
												not-found-content="Nenhum grupo adicionado até o momento"
											>
												<a-select-option
													v-for="group in productsGroups"
													:key="group.id"
												>
													{{ group.name }}
												</a-select-option>
											</a-select>
											<a-tooltip v-if="showGroupDrawer">
												<template slot="title">
													Adicionar grupo
												</template>
												<a-button
													type="primary"
													icon="plus"
													style="width: 10%;"
													:loading="loading"
													@click="
														showAddProductsGroup
													"
												/>
											</a-tooltip>
										</a-input-group>
									</a-form-item>
								</a-form>
							</a-col>
							<a-col :span="7" :style="{ minHeight: '180px' }">
								<a-form-item label="Imagem">
									<div class="ant-upload-preview">
										<div
											class="mask"
											@click="$refs.modal.setShowModal()"
										>
											<a-icon type="edit" />
										</div>
										<img :src="preview" />
									</div>
								</a-form-item>
							</a-col>
						</a-row>
					</a-form>
				</a-tab-pane>
			</a-tabs>
		</div>
		<div class="drawer-footer">
			<a-button style="margin-right: 8px;" @click="onClose">
				Cancelar
			</a-button>
			<a-button type="primary" @click="onSave">
				Salvar
			</a-button>
		</div>
		<AvatarModal ref="modal" @ok="setAvatar" />
		<GroupDrawer
			v-if="showGroupDrawer"
			ref="groupDraweR"
			:title="groupDrawerTitle"
			:products-availables="productsAvailables"
			@update="getData"
		/>
	</a-drawer>
</template>

<script>
import { AvatarModal } from '@/modules/partner/components'
import { mapActions, mapGetters } from 'vuex'
import { VMoney } from 'v-money'
import { mask } from 'vue-the-mask'
import { capitalizeFirstLetter } from '@/utils'
import { toCurrencyFormat, currencytoNumber } from '@/utils/utils'
import { GroupDrawer } from '@/modules/partner/pages/groupsPage/components'

const ElementInitialState = {
	external_code: '',
	image: null,
	name: '',
	description: '',
	price: '0.00',
	is_available: true,
}

export default {
	name: 'ProductDrawer',
	directives: { money: VMoney, mask },
	components: {
		AvatarModal,
		GroupDrawer,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			showDrawer: false,
			element: { ...ElementInitialState },
			preview: 'img/placeholder-image.png',
			updating: false,
			imageChanged: false,
			money: {
				decimal: ',',
				thousands: '.',
				precision: 2,
				masked: false,
			},
			productsGroups: [],
			productsAvailables: [],
			loading: false,
			groupDrawerTitle: '',
			showGroupDrawer: false,
			form: this.$form.createForm(this),
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
	},
	created() {
		this.getData()
	},
	methods: {
		...mapActions('menu', ['addSubsection']),
		...mapActions('merchants', ['getProductsGroupsList']),
		async getData() {
			try {
				this.loading = true
				const res = await this.getProductsGroupsList(
					this.selectedMerchantId
				)

				const { productgroups, available_products } = res

				this.productsGroups = productgroups
				this.productsAvailables = available_products
			} catch (error) {
				console.log(error)
			} finally {
				this.loading = false
			}
		},
		setShowDrawer(currentState = null, showGroupDrawer = false) {
			if (currentState !== null) {
				this.updating = true

				const { price, group_id } = currentState

				this.element = {
					...currentState,
					price: toCurrencyFormat(price),
					group_id: group_id !== null ? group_id : undefined,
				}

				if (currentState.image) this.preview = currentState.image
			}

			this.showDrawer = true
			this.showGroupDrawer = showGroupDrawer
		},
		setShowDrawerDup(product) {
			const { name, price, group_id } = product

			this.element = {
				...product,
				created_at: undefined,
				last_update: undefined,
				image: undefined,
				name: `${name} (cópia)`,
				price: toCurrencyFormat(price),
				group_id: group_id !== null ? group_id : undefined,
			}

			product.external_code === null && (this.element.external_code = '')
			this.showDrawer = true
		},
		setAvatar(img) {
			this.element.image = img
			this.preview = window.URL.createObjectURL(img)
			this.imageChanged = true
		},
		onClose() {
			this.showDrawer = false
			this.element = { ...ElementInitialState }
			this.updating = false
			this.imageChanged = false
		},
		onSave() {
			if (this.element.name === '') {
				this.$message.error(`O nome do produto não foi informado.`)
				return
			}

			const { name, description, price } = this.element
			this.element.name = capitalizeFirstLetter(name)
			this.element.description = capitalizeFirstLetter(description)
			this.element.price = currencytoNumber(price)

			if (!this.element.group_id) delete this.element.group_id

			if (this.updating) {
				const data = { ...this.element }
				if (!this.imageChanged) data.image = null

				const payload = {
					merchantId: this.selectedMerchantId,
					productId: this.element.id,
					data,
				}

				this.$emit('update', payload)
			} else this.$emit('save', this.element)

			this.onClose()
		},
		addNewSubsection(subsection) {
			subsection.section_id = this.sectionSelected.id
			this.addSubsection(subsection).then((id) =>
				this.element.subsections_id.push(id)
			)
		},
		removeSubsection(toRemove) {
			this.element.subelements = this.element.subelements.filter(
				(subelement) => subelement.name !== toRemove.name
			)
		},
		showAddProductsGroup() {
			this.groupDrawerTitle = 'Adicionar grupo'
			this.$refs.groupDraweR.setShowDrawer(null, false)
		},
	},
}
</script>

<style lang="less" scpoded>
.drawer-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	border-top: 1px solid hsv(0, 0, 85%);
	padding: 10px 16px;
	text-align: right;
	left: 0;
	background: #fff;
	border-radius: 0 0 4px 4px;
}
.avatar-upload-wrapper {
	height: 200px;
	width: 100%;
}
.ant-upload-preview {
	position: relative;
	margin: 0 auto;
	width: 100%;
	max-width: 240px;

	.mask {
		opacity: 0;
		position: absolute;
		background: rgba(0, 0, 0, 0.4);
		cursor: pointer;
		transition: opacity 0.4s;
		&:hover {
			opacity: 1;
		}
		i {
			font-size: 2rem;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -1rem;
			margin-top: -1rem;
			color: #d6d6d6;
		}
	}
	img,
	.mask {
		width: 100%;
		max-width: 200px;
		height: 100%;
		border-radius: 5px;
		overflow: hidden;
	}
}
.size-span-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0;

	.size-span {
		color: rgba(0, 0, 0, 0.45);
		font-size: 12px;
		line-height: 10px;
		align-self: flex-end;
	}
}

.element-menu-list {
	padding: 10px 0;
	height: 100vh;
}
</style>
