<template>
	<a-drawer
		:title="title"
		width="600px"
		:closable="false"
		:visible="showDrawer"
		:after-visible-change="visibilityChange"
		:body-style="{ padding: '16px 0px' }"
		@close="onClose"
	>
		<div>
			<a-row type="flex" justify="start" :gutter="16" class="row">
				<a-col :span="17">
					<a-form-item :label="`Nome do grupo`" :auto-focus="true">
						<div class="size-span-wrapper">
							<a-input
								v-model="group.name"
								:max-length="50"
								:auto-focus="true"
								@pressEnter="saveProductGroup"
							/>
							<span class="size-span" style="padding-top: 3px;">
								{{ group.name.length }}/50
							</span>
						</div>
					</a-form-item>
				</a-col>
				<a-col :span="4">
					<a-button
						type="primary"
						class="btn-save"
						:disabled="group.name.length === 0"
						@click="saveProductGroup"
					>
						Salvar
					</a-button>
				</a-col>
			</a-row>
			<ProductsList
				ref="productsList"
				:active="group.id !== undefined"
				:show-product-drawer="showProductDrawer"
				@checked-change="onCheckedChange"
			/>
		</div>
		<div class="drawer-footer">
			<a-button type="primary" @click="onClose">
				Fechar
			</a-button>
		</div>
	</a-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { capitalizeFirstLetter } from '@/utils'
import ProductsList from './productsList.vue'

const groupSchema = {
	id: undefined,
	name: '',
	products: [],
}

export default {
	name: 'GroupDrawer',
	components: {
		ProductsList,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
		productsAvailables: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			group: { ...groupSchema },
			showDrawer: false,
			updating: false,
			loading: false,
			showProductDrawer: true,
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		allProducts: function () {
			const productsChecked = this.group.products.map((product) => ({
				...product,
				checked: true,
			}))

			return [...productsChecked, ...this.productsAvailables]
		},
	},
	mounted() {},
	methods: {
		...mapActions('merchants', [
			'addProductGroup',
			'editProductGroup',
			'addProductToGroup',
			'deleteProductFromGroup',
			'getProducts',
		]),
		...mapActions('alert', ['error']),
		async setShowDrawer(currentState = null, showProductDrawer = true) {
			if (currentState) {
				this.group = { ...currentState }
			}

			this.showDrawer = true
			this.showProductDrawer = showProductDrawer
		},
		onClose() {
			this.showDrawer = false
			this.group = { ...groupSchema }
			this.$refs.productsList.clearList()

			this.$emit('update')
		},
		async saveProductGroup() {
			const payload = {
				merchantId: this.selectedMerchantId,
				groupId: this.group.id,
				data: {
					name: capitalizeFirstLetter(this.group.name),
				},
			}

			try {
				this.content = ''
				this.$message.loading({
					content: 'Salvando dados...',
					key: 'updatable',
				})

				if (this.group.id !== undefined) {
					this.group = await this.editProductGroup(payload)
				} else {
					this.group = await this.addProductGroup(payload)
				}

				this.$emit('update')

				this.$message.success({
					content: 'Dados salvos com sucesso',
					key: 'updatable',
					duration: 4,
				})
			} catch (error) {
				//
			}
		},
		async onCheckedChange(values) {
			const { productId, value } = values

			try {
				const payload = {
					merchantId: this.selectedMerchantId,
					groupId: this.group.id,
					data: {
						product_id: productId,
					},
				}

				if (!value) {
					await this.deleteProductFromGroup(payload)
				} else {
					await this.addProductToGroup(payload)
				}
			} catch (error) {
				this.error(error)
			}
		},
		visibilityChange(visible) {
			if (visible)
				this.$refs.productsList.setProductsList(this.allProducts)
		},
	},
}
</script>

<style lang="less" scpoded>
.row {
	padding-left: 24px;
	padding-right: 8px;
}

.drawer-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	border-top: 1px solid hsv(0, 0, 85%);
	padding: 10px 16px;
	text-align: right;
	left: 0;
	background: #fff;
	border-radius: 0 0 4px 4px;
}

.size-span-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0;

	.size-span {
		color: rgba(0, 0, 0, 0.45);
		font-size: 12px;
		line-height: 10px;
		align-self: flex-end;
	}
}

.btn-save {
	position: relative;
	top: 39px;
}
</style>
