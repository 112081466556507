<template>
	<div>
		<div
			v-if="isLoadingProducts"
			class="loading-spin"
			style="padding-top: 30px; margin-bottom: 32px;"
		>
			<a-spin>
				<a-icon
					slot="indicator"
					type="loading"
					style="font-size: 50px;"
					spin
				/>
			</a-spin>
		</div>
		<ul v-else-if="hasProducts" class="list-group">
			<li v-for="product in products" :key="product.id" class="list-item">
				<a-row type="flex" justify="start" :gutter="16">
					<a-col>
						<a-avatar
							shape="square"
							:size="60"
							icon="picture"
							:src="product.image"
						/>
					</a-col>
					<a-col class="item-content">
						<a-row type="flex" :gutter="16">
							<a-col :span="12" class="info-container">
								<span class="name">
									{{ getInfoHeader(product) }}
								</span>
								<span class="description">
									{{ product.description }}
								</span>
							</a-col>
							<a-col :span="3" class="info-container">
								<MoneyValue :value="product.price" />
							</a-col>
							<a-col :span="4" class="info-container">
								<span class="last-updated">atualizado</span>
								<span class="last-updated">
									{{
										`${moment(product.last_update).fromNow(
											true
										)} atrás`
									}}
								</span>
							</a-col>
							<a-col :span="5" class="info-container">
								<ActionsButtons
									:is-available="product.is_available"
									description="produto"
									@switch="
										(value) =>
											handleSwitch(product.id, value)
									"
									@duplicate="handleProductDuplicate(product)"
									@edit="handleProductEdit(product.id)"
									@delete="handleProductDelete(product.id)"
								/>
							</a-col>
						</a-row>
					</a-col>
				</a-row>
			</li>
		</ul>
		<p v-else class="empty-list">
			Nenhum produto adicionado até o momento.
		</p>
	</div>
</template>

<script>
import { ActionsButtons, MoneyValue } from '@/modules/partner/components'
import { mapGetters } from 'vuex'

export default {
	name: 'PrductsList',
	components: {
		ActionsButtons,
		MoneyValue,
	},
	props: {
		products: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
			isLoadingProducts: 'merchants/isLoadingProducts',
		}),
		hasProducts: function () {
			return this.products.length > 0
		},
	},
	methods: {
		getInfoHeader(product) {
			return product.external_code
				? `${product.external_code} - ${product.name}`
				: product.name
		},
		async handleSwitch(productId, value) {
			const payload = {
				productId: productId,
				is_available: value,
				merchantId: this.selectedMerchantId,
			}

			this.$emit('change', payload)
		},
		async handleProductDelete(productId) {
			const payload = {
				productId: productId,
				merchantId: this.selectedMerchantId,
			}
			this.$emit('delete', payload)
		},
		async handleProductEdit(ProductId) {
			this.$emit('edit', ProductId)
		},
		async handleProductDuplicate(Product) {
			this.$emit('duplicate', Product)
		},
	},
}
</script>

<style lang="less" scope>
@import url('@/config/globalStyles.less');

.list-group {
	margin: 0;
	padding: 0;

	.list-item {
		list-style-type: none;
		padding: 0px 16px 8px;
		margin-bottom: 8px;

		.item-content {
			border-bottom: 1px solid hsv(0, 0, 85%);
			padding: 0px 8px;
			width: calc(100% - 80px);
			height: 70px;

			.info-container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 56px;

				.name {
					font-size: 16px;
					font-weight: 501;
					color: #000;
				}

				.description {
					font-size: 14px;
					color: rgba(0, 0, 0, 0.55);
					overflow-x: hidden;
					max-width: 600px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.last-updated {
					font-size: 12px;
					color: rgba(0, 0, 0, 0.45);
					font-weight: 401;
					text-align: center;
				}
			}
		}
	}
}
</style>
