<template>
	<div class="container">
		<a-row class="header" type="flex" justify="space-between">
			<a-col :span="11">
				<a-row
					type="flex"
					justify="start"
					style="padding-top: 4px;"
					:gutter="16"
				>
					<a-col v-for="header in headers" :key="header.key">
						<span class="title">{{ header.text }}:</span>
						<a-badge
							:count="header.value"
							:number-style="{
								backgroundColor: '#e8e8e8',
								color: '#000',
								fontWeight: 'bold',
							}"
							style="margin: 0px 8px 5px;"
							:show-zero="true"
							:overflow-count="999"
						/>
					</a-col>
				</a-row>
			</a-col>
			<a-col :span="13" class="filter-container">
				<a-input-group compact>
					<a-input
						v-model="filterString"
						style="width: 70%;"
						placeholder="Filtrar produtos por... "
						@change="handleChangeFilterString"
					>
						<a-icon slot="prefix" type="search" />
					</a-input>
					<a-select
						v-model="filterOption"
						style="width: 30%;"
						@change="filteredProducts = filterString"
					>
						<a-select-option
							v-for="option in searchOptions"
							:key="option.value"
							v-model="option.value"
						>
							{{ option.text }}
						</a-select-option>
					</a-select>
				</a-input-group>
				<a-select
					style="width: 40%; margin-left: 8px;"
					:value="sortMode"
					@change="handleSortChange"
				>
					<a-select-opt-group>
						<span
							slot="label"
							style="font-size: 11px; color: rgba(0, 0, 0, 0.75);"
						>
							ORDENAR POR
						</span>
						<a-select-option
							v-for="option in sortOptions"
							:key="option.value"
							:value="option.value"
						>
							{{ option.text }}
						</a-select-option>
					</a-select-opt-group>
				</a-select>
			</a-col>
		</a-row>
		<a-row class="body">
			<ProductsList
				:products="filteredProducts"
				@change="handleProductsChange"
				@delete="handleProductDelete"
				@duplicate="handleProductDuplicate"
				@edit="handleProductEdit"
			/>
		</a-row>
		<ProductDrawer
			ref="productDrawer"
			:title="productDrawerTitle"
			@save="handleSaveProduct"
			@update="handleUpdateProduct"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { userStorage } from '@/utils'
import ProductsList from './productsList'
import ProductDrawer from './productDrawer'

const sortOptions = [
	{ value: 'code', text: 'código' },
	{ value: 'name', text: 'nome' },
	{ value: 'highest', text: 'maior valor' },
	{ value: 'lowest', text: 'menor valor' },
	{ value: 'oldest', text: 'mais antigos' },
	{ value: 'newest', text: 'mais novos' },
	{ value: 'lastUpdated', text: 'modificados recentemente' },
	{ value: 'oldUpdated', text: 'modificados antigamente' },
]

const searchOptions = [
	{ value: 'code', text: 'código' },
	{ value: 'name', text: 'nome' },
	{ value: 'description', text: 'descrição' },
]

export default {
	name: 'ProductContainer',
	components: {
		ProductsList,
		ProductDrawer,
	},
	data() {
		return {
			sortOptions,
			searchOptions,
			filterString: '',
			filterOption: 'name',
			productDrawerTitle: '',
			sortMode: 'code',
			products: [],
		}
	},
	computed: {
		...mapGetters({
			allProducts: 'merchants/allProducts',
			isMerchantActive: 'merchants/isMerchantActive',
		}),
		haveProducts: function () {
			return this.allProducts ? this.allProducts.length > 0 : false
		},
		headers: function () {
			const headers = [
				{
					key: 'total',
					text: 'Total de produtos',
					value:
						(this.filteredProducts &&
							this.filteredProducts.length) ||
						0,
				},
				{
					key: 'unavailable',
					text: 'Indisponíveis',
					value:
						(this.filteredProducts &&
							this.filteredProducts.filter(
								(product) => !product.is_available
							).length) ||
						0,
				},
			]

			return headers
		},
		filteredProducts: {
			set: function (filterString) {
				if (filterString !== '') {
					let elementsList = []
					let index, value
					let property = ''

					switch (this.filterOption) {
						case 'code':
							property = 'external_code'
							break
						case 'name':
							property = 'name'
							break
						case 'description':
							property = 'description'
							break
					}

					for (index = 0; index < this.allProducts.length; ++index) {
						value = this.allProducts[index][property]
						if (
							value &&
							typeof value === 'string' &&
							value
								.toLowerCase()
								.indexOf(filterString.toLowerCase()) >= 0
						) {
							elementsList.push(this.allProducts[index])
						}
					}

					this.products = [...elementsList]
				} else {
					this.products = [...this.allProducts]
				}

				this.handleSortChange(this.sortMode)
			},
			get: function () {
				return this.products
			},
		},
	},
	watch: {
		allProducts: function () {
			this.filteredProducts = this.filterString
		},
	},
	async created() {
		if (!this.isMerchantActive) {
			this.$router.push('/home')
		} else {
			await this.getProducts()
		}
	},
	methods: {
		...mapActions('merchants', [
			'getProducts',
			'addProduct',
			'editProduct',
			'deleteProduct',
			'switchProduct',
		]),
		onShowSizeChange(_, pageSize) {
			userStorage.set({ productsPageSize: pageSize })
		},
		handleSortChange(value) {
			switch (value) {
				case 'code':
					this.sortProductsBy('external_code')
					break
				case 'name':
					this.sortProductsBy('name')
					break
				case 'highest':
					this.sortProductsBy('price', 1)
					break
				case 'lowest':
					this.sortProductsBy('price')
					break
				case 'oldest':
					this.sortProductsBy('created_at')
					break
				case 'newest':
					this.sortProductsBy('created_at', 1)
					break
				case 'lastUpdated':
					this.sortProductsBy('last_update', 1)
					break
				case 'oldUpdated':
					this.sortProductsBy('last_update')
					break
				default:
					this.sortProductsBy('external_code')
					break
			}

			this.sortMode = value
		},
		sortProductsBy(property, order = 0) {
			let func = null

			if (order === 0) {
				func = (a, b) =>
					a[property] > b[property]
						? 1
						: b[property] > a[property]
						? -1
						: 0
			} else {
				func = (a, b) =>
					a[property] < b[property]
						? 1
						: b[property] < a[property]
						? -1
						: 0
			}

			this.filteredProducts.sort(func)
		},
		async handleProductsChange(payload) {
			await this.switchProduct(payload)
		},
		showAddProduct() {
			this.productDrawerTitle = 'Adicionar produto'
			this.$refs.productDrawer.setShowDrawer()
		},
		async handleSaveProduct(product) {
			try {
				await this.addProduct(product)
				this.handleSortChange(this.sortMode)
			} catch (error) {
				console.log(error)
			}
		},
		handleProductEdit(productId) {
			this.productDrawerTitle = 'Editar produto'
			let product = this.allProducts.find(
				(product) => product.id === productId
			)

			if (product.external_code === null) product.external_code = ''

			this.$refs.productDrawer.setShowDrawer(product)
		},
		async handleProductDelete(payload) {
			await this.deleteProduct(payload)
		},
		handleProductDuplicate(product) {
			let copy = { ...product }

			delete copy.id
			delete copy.created_at
			delete copy.last_update
			copy.image = null
			copy.name = `${product.name} (cópia)`

			if (!copy.group_id) delete copy.group_id

			this.productDrawerTitle = 'Duplicar produto'
			this.$refs.productDrawer.setShowDrawerDup(copy)
		},
		async handleUpdateProduct(edited) {
			await this.editProduct(edited)
		},
		handleChangeFilterString(e) {
			this.filteredProducts = e.target.value
		},
	},
}
</script>

<style lang="less" scoped>
@import url('@/config/globalStyles.less');

.container {
	margin: 16px auto 24px;
	border: 1px solid hsv(0, 0, 85%);
	border-radius: 5px;

	.header {
		margin: 0px auto 16px;
		padding: 8px 16px;
		border-bottom: 1px solid hsv(0, 0, 85%);

		.title {
			font-weight: 501;
			font-size: 15px;
			color: @secondary;
		}

		.filter-container {
			display: flex;
			justify-content: flex-end;
		}
	}

	.footer {
		margin: 0px auto 16px;
		display: flex;
		justify-content: center;
	}
}
</style>
